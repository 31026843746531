@charset "UTF-8";
.fullpage-container .pc-bg {
  position: absolute;
  top: 50px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 200px;
}
.fullpage-container .pc-bg #inner-container {
  top: -60px;
}
.fullpage-container .pc-bg #inner-container .section img {
  display: block;
  height: 80vh;
  min-height: 300px;
}
.fullpage-container .pc-bg .desc-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  color: #fff;
  height: 100%;
}
.fullpage-container .pc-bg .desc-box h3 {
  font-weight: normal;
  font-size: 9rem;
  font-size: 3.5vw;
}
.fullpage-container .pc-bg .desc-box p {
  margin: 40px 0 50px;
  padding: 0;
  font-size: 3rem;
  font-size: 2.5vw;
}
.fullpage-container .pc-bg .desc-box .ios,
.fullpage-container .pc-bg .desc-box .android,
.fullpage-container .pc-bg .desc-box .apk {
  margin-top: 30px;
  display: block;
}
.fullpage-container .pc-bg .desc-box .ios img,
.fullpage-container .pc-bg .desc-box .android img,
.fullpage-container .pc-bg .desc-box .apk img {
  width: 100%;
}
.fullpage-container .pc-bg.bg1 {
  background: url("//image.micoworld.net/46b91d8f0cc0e419a08458b600bbaa69") no-repeat center, linear-gradient(134deg, #522df1 0%, #7a37f6 100%);
  background-size: cover;
}
.fullpage-container .pc-bg.bg2 {
  background: url("//image.micoworld.net/a73c275a03fe6127ea68649363e1486e") no-repeat center, linear-gradient(315deg, #00caff 0%, #007fff 100%);
  background-size: cover;
}
.fullpage-container .pc-bg.bg3 {
  background: url("//image.micoworld.net/c285617bc4e55ee53e506f6feafb219c") no-repeat center, linear-gradient(135deg, #ff00a3 0%, #ff894d 100%);
  background-size: cover;
}
.fullpage-container .pc-bg.bg4 {
  background: url("//image.micoworld.net/f87d3e34e1cbe4ac97f73445500ba28e") no-repeat center, linear-gradient(135deg, #00c8e1 0%, #31ff94 100%);
  background-size: cover;
}
.fullpage-container .pc-bg.bg5 {
  background: url("//image.micoworld.net/d1af358f2f9c493dba782f0ff8e974e8") no-repeat center, linear-gradient(135deg, #ff862c 0%, #ffc13e 100%);
  background-size: cover;
}
.fullpage-container .mobile-bg {
  position: absolute;
  top: 50px;
  bottom: 100px;
  width: 100%;
}
.fullpage-container .mobile-bg .swiper-container {
  height: 100%;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .desc-box {
  position: relative;
  color: #fff;
  text-align: center;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .desc-box h3 {
  font-size: 16px;
  margin: 20px auto 5px;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .desc-box p {
  font-size: 12px;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .section {
  position: relative;
  text-align: center;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .section img {
  width: 59.2vw;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .bg1,
.fullpage-container .mobile-bg .swiper-container .swiper-slide .bg2,
.fullpage-container .mobile-bg .swiper-container .swiper-slide .bg3,
.fullpage-container .mobile-bg .swiper-container .swiper-slide .bg4,
.fullpage-container .mobile-bg .swiper-container .swiper-slide .bg5 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 90px;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .bg1 {
  background: url("//image.micoworld.net/6526f5c706998731ca7ae05203b7713e") no-repeat center, linear-gradient(134deg, #522df1 0%, #7a37f6 100%);
  background-size: cover;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .bg2 {
  background: url("//image.micoworld.net/eb968d40efee8503e590dfa65eb484a4") no-repeat center, linear-gradient(315deg, #00caff 0%, #007fff 100%);
  background-size: cover;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .bg3 {
  background: url("//image.micoworld.net/ab8b076947712137aae8b0a308a420d6") no-repeat center, linear-gradient(135deg, #ff00a3 0%, #ff894d 100%);
  background-size: cover;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .bg4 {
  background: url("//image.micoworld.net/b83269aa54be87738f2c0da0ba5d4eb6") no-repeat center, linear-gradient(135deg, #00c8e1 0%, #31ff94 100%);
  background-size: cover;
}
.fullpage-container .mobile-bg .swiper-container .swiper-slide .bg5 {
  background: url("//image.micoworld.net/554ab3b796372299ce17b21f5137d995") no-repeat center, linear-gradient(135deg, #ff862c 0%, #ffc13e 100%);
  background-size: cover;
}
.fullpage-container .mobile-bg .swiper-container .swiper-pagination-bullets {
  position: absolute;
  top: 65px;
}
.fullpage-container .mobile-dl {
  position: absolute;
  bottom: 0;
  height: 100px;
  left: 20px;
  right: 20px;
}
.fullpage-container .mobile-dl a {
  display: block;
  width: 100%;
}
.fullpage-container .mobile-dl a img {
  display: block;
  width: 100%;
}
