.nav-end {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 200px;

  .container {
    margin-top: 100px;

    li {
      a {
        padding: 5px 10px;
      }

      &.line {
        a {
          color: #ccc;

          &:hover {
            background: transparent;
            cursor: default;
          }
        }
      }
    }
  }
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  background: #fff;
}
