.nav-end {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 200px;
}
.nav-end .container {
  margin-top: 100px;
}
.nav-end .container li a {
  padding: 5px 10px;
}
.nav-end .container li.line a {
  color: #ccc;
}
.nav-end .container li.line a:hover {
  background: transparent;
  cursor: default;
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: #fff;
}
