@charset "UTF-8";

.navbar {
  background: transparent;

  .container {

    .navbar-header {
      color: #A6B0BD;

      .navbar-toggle {
        background: #eee;

        .icon-bar {
          background-color: #6050FF;
        }
      }

      .navbar-brand {
        margin-right: 30px;

        .logo {
          width: 106px;
          height: 30px;
          margin-top: -3px;
          display: inline-block;
        }
      }
    }

    .navbar-collapse {

      .navbar-nav {

        li {
          font-size: 18px;

          a {
            font-weight: bold;
            color: #1D212C;

            .app-icon, .recharge-icon {
              display: inline-block;
              width: 16px;
              height: 16px;
              vertical-align: middle;
            }

            .app-icon {
              background: url("~@public/assets/web/icon_app_down_n@2x.png") no-repeat center;
              background-size: contain;
            }

            .recharge-icon {
              background: url("~@public/assets/web/ic_recharge_n@2x.png") no-repeat center;
              background-size: contain;
            }

            span {
              vertical-align: middle;
            }

            &:hover {
              color: #6050FF;

              .app-icon {
                background: url("~@public/assets/web/icon_music_down_s@2x.png") no-repeat center;
                background-size: contain;
              }

              .recharge-icon {
                background: url("~@public/assets/web/ic_recharge_s@2x.png") no-repeat center;
                background-size: contain;
              }
            }
          }

          &.active {
            a {
              color: #6647FF;
            }
          }
        }
      }

      .navbar-right {
        li {
          font-size: 1.5rem;

          &:first-child {
            a {
              .dt {
                display: none;
              }
            }

            a:hover {
              .dt {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.mobile {
  .navbar-toggle {
    float: left;
    margin-left: 15px;
    margin-right: 0;
  }

  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-group {
      list-style-type: none;
      padding: 0;
      float: left;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      height: 50px;

      li {
        a {
          color: #1D212C;
          font-size: 16px;
          text-decoration: none;
        }

        &:first-child {
          margin-right: 30px;
        }

        &.active {
          a {
            color: #6050FF;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 20px;
          height: 3px;
          background: #F5F7F9;
          margin: 4px auto 0;
        }

        &.active:after {
          background: #6050FF;
        }
      }
    }

    .recharge {
      flex-basis: 80px;
      font-size: 15px;
      text-align: center;

      a {

        color: #A6B0BD;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .navbar {
    background-color: #F5F7F9;
  }
}
