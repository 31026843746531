@charset "UTF-8";
.navbar {
  background: transparent;
}
.navbar .container .navbar-header {
  color: #A6B0BD;
}
.navbar .container .navbar-header .navbar-toggle {
  background: #eee;
}
.navbar .container .navbar-header .navbar-toggle .icon-bar {
  background-color: #6050FF;
}
.navbar .container .navbar-header .navbar-brand {
  margin-right: 30px;
}
.navbar .container .navbar-header .navbar-brand .logo {
  width: 106px;
  height: 30px;
  margin-top: -3px;
  display: inline-block;
}
.navbar .container .navbar-collapse .navbar-nav li {
  font-size: 18px;
}
.navbar .container .navbar-collapse .navbar-nav li a {
  font-weight: bold;
  color: #1D212C;
}
.navbar .container .navbar-collapse .navbar-nav li a .app-icon,
.navbar .container .navbar-collapse .navbar-nav li a .recharge-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
.navbar .container .navbar-collapse .navbar-nav li a .app-icon {
  background: url("~@public/assets/web/icon_app_down_n@2x.png") no-repeat center;
  background-size: contain;
}
.navbar .container .navbar-collapse .navbar-nav li a .recharge-icon {
  background: url("~@public/assets/web/ic_recharge_n@2x.png") no-repeat center;
  background-size: contain;
}
.navbar .container .navbar-collapse .navbar-nav li a span {
  vertical-align: middle;
}
.navbar .container .navbar-collapse .navbar-nav li a:hover {
  color: #6050FF;
}
.navbar .container .navbar-collapse .navbar-nav li a:hover .app-icon {
  background: url("~@public/assets/web/icon_music_down_s@2x.png") no-repeat center;
  background-size: contain;
}
.navbar .container .navbar-collapse .navbar-nav li a:hover .recharge-icon {
  background: url("~@public/assets/web/ic_recharge_s@2x.png") no-repeat center;
  background-size: contain;
}
.navbar .container .navbar-collapse .navbar-nav li.active a {
  color: #6647FF;
}
.navbar .container .navbar-collapse .navbar-right li {
  font-size: 1.5rem;
}
.navbar .container .navbar-collapse .navbar-right li:first-child a .dt {
  display: none;
}
.navbar .container .navbar-collapse .navbar-right li:first-child a:hover .dt {
  display: block;
}
.mobile .navbar-toggle {
  float: left;
  margin-left: 15px;
  margin-right: 0;
}
.mobile .menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile .menu .head-group {
  list-style-type: none;
  padding: 0;
  float: left;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 50px;
}
.mobile .menu .head-group li a {
  color: #1D212C;
  font-size: 16px;
  text-decoration: none;
}
.mobile .menu .head-group li:first-child {
  margin-right: 30px;
}
.mobile .menu .head-group li.active a {
  color: #6050FF;
}
.mobile .menu .head-group li:after {
  content: '';
  display: block;
  width: 20px;
  height: 3px;
  background: #F5F7F9;
  margin: 4px auto 0;
}
.mobile .menu .head-group li.active:after {
  background: #6050FF;
}
.mobile .menu .recharge {
  flex-basis: 80px;
  font-size: 15px;
  text-align: center;
}
.mobile .menu .recharge a {
  color: #A6B0BD;
}
@media all and (max-width: 768px) {
  .navbar {
    background-color: #F5F7F9;
  }
}
